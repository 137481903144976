
// Called on page load
function blendInNavi() {
    var animationDuration = 35;
    var DelayContent = 40;
    var Counter = 0;

    $('#menu').addClass('show');
    $('#menu li').each(function(i) {
        var Ele = $(this);
        setTimeout(function() {
            Ele.addClass('show');
        }, animationDuration * i);
        Counter += 1;
    });
    setTimeout(function() {
        blendIn();
    }, (DelayContent) * Counter);
}

function blendIn() {
    var TimeBlend = 150;
    $('.blend-in').each(function(i) {
        var Ele = $(this);
        setTimeout(function() {
            Ele.addClass('show');
        }, TimeBlend * i);
    });
}

// Called on scroll
function NaviFade() {
    // $(document).bind("touchmove", function(e) {
    //     $('.nav-fix').removeClass('transition');
    //     $('.nav-fix').addClass('show');
    // });
    ticking = false;

    if (latestKnownScrollY > 50) {
        $('header').addClass('scrolled');
    } else {
        $('header').removeClass('scrolled');
    }
}
